/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
// import Menu from '../../components/Menu/Menu'
import { i18n } from '../../utils/translates/i18n'
import { /* getActiveUsersParam, */ getUsersNonList } from '../../services/UsersService'
import { getMovimentTypes, sendCredDebt } from '../../services/CredDebtService'
import LoaderPage from '../../utils/LoaderPage'
import { toast } from 'react-toastify'
// import { decrypt } from '../../utils/crypto'

function CredDeb() {
  const token = sessionStorage.getItem('token')
  // const rule = decrypt(sessionStorage.getItem('rule'))
  // const role = decrypt(sessionStorage.getItem('role'))
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')
  const [dataPost, setDataPost] = useState({ user: '', type: '', amount: 0 })
  const [show, setShow] = useState(false)
  const [movimentTypes, setMovimentTypes] = useState([])

  const doGetUsers = async () => {
    const success = await getUsersNonList(search)
    if (success.status) setUsers(success.users)
  }

  const onInputChange = e => setDataPost(p => ({ ...p, [e.target.id]: e.target.value }))

  const doSave = async () => {
    setShow(false)
    const post = {
      ...dataPost,
      user: dataPost.user.split(' | ')[0],
      cd: true,
    }
    if (!!parseInt(post.user)) {
      const success = await sendCredDebt(token, post)
      if (success.status) toast.success(`Credito / Débito enviado com sucesso para ${dataPost.user.split(' | ')[1]}`)
      setDataPost({ user: '', type: '', amount: 0 })
      setShow(true)
    } else {
      setShow(true)
      toast.error('Escolha um usuário na lista')
    }
  }

  useEffect(() => {
    if (search?.length > 2) doGetUsers()
    setShow(true)
  }, [search])

  useEffect(() => {
    const doGetMovimentTypes = async () => {
      const success = await getMovimentTypes()
      if (success.status) {
        setMovimentTypes(success.types)
        setShow(true)
      }
    }
    doGetMovimentTypes()
  }, [])

  return (
    <>
      {/* <Menu /> */}

      <main className="content">
        {!show ? (
          <LoaderPage />
        ) : (
          <>
            <div className="container-fluid">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                  <div className="h3">{i18n.t('cred_deb')}</div>
                </div>
              </div>
              <div className="card card-body border-0 shadow mb-5">
                <div className="h5">{i18n.t('cred_deb_title')}</div>
                <div className="row">
                  <div className="col-md">
                    <div className="input-group">
                      <span className="btn btn-primary">{i18n.t('user')}</span>
                      <input
                        list="userlist"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className="form-control"
                        placeholder="digite"
                      />
                      {users?.length > 0 && (
                        <select className="form-control" id="user" value={dataPost.user} onChange={onInputChange}>
                          <option value="">-- {i18n.t('choose')} --</option>
                          {users.map((u, i) => (
                            <option key={i} value={u.id}>{`${u.nick} (${u.name})`}</option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md">
                    <div className="input-group">
                      <span className="btn btn-primary">{i18n.t('user')}</span>
                      <select id="user" value={dataPost.user} onChange={onInputChange} className="form-select">
                        <option value="">-- {i18n.t('choose')} --</option>
                        {users && users.map((u, i) => <option key={i} value={u.id}>{`${u.nick} - ${u.name}`}</option>)}
                      </select>
                    </div>
                  </div> */}
                  <div className="col-md">
                    <div className="input-group">
                      <span className="btn btn-primary">{i18n.t('type')}</span>
                      <select id="type" value={dataPost.type} onChange={onInputChange} className="form-select">
                        <option value="">-- {i18n.t('choose')} --</option>
                        <option value="4" className={`text-danger fw-bold`}>{`${i18n.t('debt_td')} (${i18n.t(
                          'available'
                        )})`}</option>
                        <option value="36" className={`text-danger fw-bold`}>{`${i18n.t('debt_td')} (${i18n.t(
                          'earnings'
                        )})`}</option>
                        {/* {movimentTypes
                          ?.sort((a, b) => (i18n.t(a.description) > i18n.t(b.description) ? 1 : -1))
                          .sort((a, b) => (a.type > b.type ? 1 : -1))
                          .sort((a, b) => (a.balance > b.balance ? 1 : -1))
                          .map(
                            (u, i) =>
                              u.type === 'debit' && (
                                <option
                                  value={u.code}
                                  className={`text-${u.type === 'credit' ? 'success' : 'danger'} fw-bold`}
                                  key={i}>
                                  {i18n.t(u.description)} (saldo: {i18n.t(u.balance)}
                                  {u.quota ? ' / quota' : ''})
                                </option>
                              )
                          )} */}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <span className="btn btn-primary">{i18n.t('amount')}</span>
                      <input
                        id="amount"
                        value={dataPost.amount}
                        type="number"
                        onChange={onInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn btn-success text-white"
                      onClick={doSave}
                      disabled={!dataPost.user || !dataPost.type || !dataPost.amount || dataPost.amount <= 0}>
                      <i className="fa fa-check"></i>
                      <span className="ms-2">{i18n.t('send')}</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* <pre>{JSON.stringify(dataPost, null, 2)}</pre> */}
            </div>
          </>
        )}
      </main>

      {/* <Footer /> */}
    </>
  )
}

export default CredDeb
