import React, { useRef, useState, useEffect } from 'react'
import { saveQuotas } from '../../../services/UsersService'
import { i18n } from '../../../utils/translates/i18n'

export default function QuotasModal(props) {
  const [qty, setQty] = useState(0)
  const [userId, setUserId] = useState(null)

  const [error, setError] = useState('')

  const btnClose = useRef('')
  const btnSave = useRef('')

  function onSubmit() {
    saveQuotas({ userId, qty })
      .then(result => {
        btnClose.current.click()
        if (props.onSubmit) props.onSubmit(result)
      })
      .catch(err => {
        console.error(err.response ? err.response.data : err.message)
        setError(err.response ? err.response.data : err.message)
      })
  }

  useEffect(() => {
    setUserId(props.data?.id)
    setQty(props.data?.quotas?.qty)
  }, [props])

  return (
    <div
      className="modal fade"
      id="modalQuotas"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {i18n.t('edit')} {i18n.t('quotas')}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="row">
                {['developer', 'manager', 'admlotteries', 'support'].includes(props.rule) && (
                  <div className="col-md mb-3">
                    <div className="form-group">
                      <label htmlFor="type">{i18n.t('investment')}:</label>
                      <input
                        type="number"
                        value={qty}
                        onChange={e => setQty(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
          </div>
          <div className="modal-footer">
            {error && <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>}
            <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
              {i18n.t('save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
