import React, { useRef, useState, useEffect } from 'react'
import { getActiveUsersParam, saveUser } from '../../../services/UsersService'
import { i18n } from '../../../utils/translates/i18n'

export default function IndicantModal(props) {
  const [activeUsers, setActiveUsers] = useState([])
  const [search, setSearch] = useState('')

  const DEFAULT_USER = {
    userId: '',
    limitId: 0,
    telegramChat: '',
    ddi: '',
    phone: '',
    isActive: false,
    photo: '',
    passwordSecurity: '',
    status: '',
    binaryQualify: false,
    activity: false,
    userGroupId: 1,
    releaseWithdrawals: false,
  }

  const [error, setError] = useState('')

  const [user, setUser] = useState(DEFAULT_USER)
  const [nick, setNick] = useState('')

  const btnClose = useRef('')
  const btnSave = useRef('')

  function onSubmit() {
    saveUser(user?.id, user)
      .then(result => {
        btnClose.current.click()
        if (props.onSubmit) props.onSubmit(result)
      })
      .catch(err => {
        console.error(err.response ? err.response.data : err.message)
        setError(err.response ? err.response.data : err.message)
      })
  }

  function onInputChange(event) {
    setUser(prevState => ({ ...prevState, [event.target.id]: event.target.value }))
  }

  useEffect(() => {
    setUser(DEFAULT_USER)
    setSearch('')
    setActiveUsers([])

    if (props.data?.id) {
      setUser({
        ...props.data,
        name: props.data?.fullName,
        status: props.data?.status?.id,
        binaryQualify: props.data?.binaryQualify?.status,
        userGroupId: props.data?.group?.id,
      })
      setNick(props.data?.nick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const getIndicants = async () => {
    const success = await getActiveUsersParam(search)
    if (success.length > 0) setActiveUsers(success)
  }

  useEffect(() => {
    if (search && search.length >= 2) getIndicants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <div
      className="modal fade"
      id="modalIndicant"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {i18n.t('edit')} {i18n.t('indicant')}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <img
                      src={
                        user?.photo && user?.photo !== 'user?.png'
                          ? `${process.env.REACT_APP_API_URL}/images/${user?.photo}`
                          : '/img/user?.png'
                      }
                      crossOrigin="anonymous"
                      alt="user"
                      className={`img-thumbnail img-fluid shadow`}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md mb-3">
                      <div className="form-group">
                        <label htmlFor="nick">{i18n.t('nick')}:</label>
                        <div className="form-control">{nick}</div>
                      </div>
                    </div>

                    {['developer', 'manager', 'admlotteries', 'support'].includes(props.rule) && (
                      <div className="col-md mb-3">
                        <div className="form-group">
                          <label htmlFor="type">
                            {i18n.t('indicant')}: {user?.indicant?.nick}
                          </label>
                          <input
                            list="userlist"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            className="form-control"
                            placeholder="digite"
                          />
                          {activeUsers.length > 0 && (
                            <select className="form-control" id="userId" value={user?.userId} onChange={onInputChange}>
                              <option value="">-- {i18n.t('choose')} --</option>
                              {activeUsers?.map((u, i) => (
                                <option key={i} value={u.id}>{`${u.nick} (${u.name})`}</option>
                              ))}
                            </select>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
          </div>
          <div className="modal-footer">
            {error && <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>}
            <button
              ref={btnSave}
              type="button"
              disabled={!user?.userId}
              className="btn btn-sm btn-primary"
              onClick={onSubmit}>
              {i18n.t('save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
