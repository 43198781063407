const messages = {
  pt: {
    translations: {
      a_crase: 'à',
      about: 'Sobre Nós',
      above_the_same: 'Acima exatamente igual',
      action: 'Ação',
      actionTemplates: 'Modelos de Ações',
      activate_date: 'Data de ativação',
      active_automations: 'Automações Ativas',
      active_connections: 'Conexões Ativas',
      active_monitors: 'Monitores Ativos',
      active_users: 'Usuários Ativos',
      ad: 'Anúncio',
      add: 'Adesão',
      add_option: 'Adicionar opção',
      add_payment: 'Adicionar Pagamento',
      add_question: 'Adicionar Questão',
      add_receipt: 'Adicionar Recebimento',
      add_wallet: 'Adicionar Carteira',
      address: 'Endereço',
      admin: 'Administração',
      advance: 'Avançar',
      affected: 'Atingido',
      after: 'Depois',
      air_transport: 'Transporte Aéreo',
      ajust_limite: 'Ajuste de Limite',
      ajust_saldo: 'Ajuste de Saldo',
      alert: 'Alerta',
      alerts: 'Alertas',
      all: 'Todos',
      all_departments: 'Todos os Departamentos',
      allies_of: 'Aliados da',
      allRightsReserved: 'Todos os direitos reservados.',
      almost_there: 'Quase lá',
      already_offset: 'Já Compensei',
      already_registered: 'Já está cadastrado? Clique aqui e entre',
      amount: 'Valor',
      amount_above_balance: 'Saldo insuficiente',
      and: 'e',
      and_more: 'e mais',
      answer: 'Resposta',
      ao: 'ao',
      approve: 'Aprovar',
      April: 'abril',
      asc: 'Ascendente',
      at: 'em',
      August: 'Agosto',
      automations: 'Automações',
      awaiting_email_confirmation: 'Aguardando confirmação do e-mail!',
      b_order: 'Ordem',
      b_orders: 'Ordens',
      back: 'Voltar',
      bairroInteresse: 'Bairro',
      balance: 'Saldo',
      balance_available: 'Saldo Disponível',
      banned: 'Banido',
      before_contact: 'Antes de entrar em contato conosco',
      binary: 'Binário',
      birth: 'Nascimento',
      blind_man: 'Estornado',
      body_monthly:
        'Sua Mensalidade venceu e seu BOT TRADER foi desativado, por favor pague a Ordem de Pagamento e volte a aproveitar seus benefícios',
      bonus_saldo_trader: 'Bônus Saldo Trader',
      book_health: 'Book Health',
      but_possible_register:
        'Mesmo assim é possível fazer o seu cadastro, porém não ficará relacionado a nenhum amigo indicante.',
      buy_volume: 'Volume de Compra',
      buyer: 'Comprador',
      by: 'por',
      bye: 'Até Logo',
      call: 'Chamado',
      call_to: 'Ligar para',
      calls: 'Chamados',
      cancel: 'Cancelar',
      canceled: 'Cancelado',
      career_plan: 'Plano de Carreira',
      casa_verde_amarela: 'Casa Verde Amarela',
      cash_payment: 'Pagamento em dinheiro',
      cash_payment_return: 'Devolução de pagamento em dinheiro',
      cash_receipt: 'Recebimento em dinheiro',
      change_indicant: 'Alterar Indicante',
      change_photo: 'Alterar Foto',
      change_plan: 'Alterar plano',
      check_payment: 'Pagamento em cheque',
      check_payment_return: 'Devolução de pagamento em cheque',
      check_receipt: 'Recebimento em cheque',
      choose: 'Escolher',
      cidadeInteresse: 'Cidade',
      city: 'Cidade',
      claims: 'Reclamações',
      clean: 'Limpar',
      click_and_know: 'Clique e Conheça',
      click_to_copy_the_wallet: 'Clique para Copiar a Carteira',
      client: 'Cliente',
      clients: 'Clientes',
      close: 'Fechamento',
      cnh: 'CNH (Habilitação)',
      cnhDate: 'Data de Emissão da CNH',
      cnpj: 'CNPJ',
      cnpj_invalid: 'CNPJ Inválido',
      coin: 'Moeda',
      color: 'Cor',
      commercial: 'Comercial',
      company: 'Nome da Empresa',
      complement: 'Complemento',
      comporRenda: 'Vai compor renda com alguém',
      compRenda: 'Tipo de Comprovação de Renda',
      confirm_approve: 'Confirmar aprovação',
      confirm_delete: 'Confirmar exclusão',
      confirm_new_password: 'Confirmar Nova Senha de Acesso',
      confirm_new_password_security: 'Confirmar nova senha de segurança',
      confirm_password: 'Confirme a Senha de Acesso',
      confirm_password_security: 'Confirme a senha de segurança',
      confirm_your_new_password: 'Digite sua nova senha novamente',
      confirmed: 'Confirmado',
      confirmed_email: 'Email confirmado',
      congratulations: 'Parabéns',
      contact: 'Contato',
      contract: 'Contrato',
      copied: 'Copiado',
      copy: 'Copiar',
      copy_success: 'Copiada com sucesso!',
      correspondences: 'Correspondências',
      countries: 'Países',
      country: 'País',
      cpf: 'CPF',
      cpf_invalid: 'CPF Inválido',
      create_order_success: 'Pedido Criado com Sucesso!',
      created: 'Registrado em',
      cred: 'Crédito',
      cred_binario: 'Crédito Binário',
      cred_deb: 'Créditos/Débitos',
      cred_game_donate: 'Crédito Game Donate',
      cred_ind_direta: 'Crédito de Indicação Direta',
      cred_ind_indireta: 'Crédito de indicação Indireta',
      cred_mandala: 'Crédito da Mandala',
      cred_pagar_com_saldo: 'Crédito Pagar com Saldo',
      cred_pontos: 'Crédito Pontos',
      cred_recb_trader: 'Crédito Receber Trader',
      cred_renovacao: 'Crédito Renovação',
      cred_saldo_disp: 'Crédito Saldo Disponível',
      cred_upgrade: 'Crédito UpGrade',
      credit_card_payment: 'Pagamento em cartão de crédito',
      credit_card_payment_return: 'Devolução de pagamento em cartão de crédito',
      credit_card_receipt: 'Recebimento em cartão de crédito',
      current: 'Atual',
      current_password: 'Senha Atual',
      current_wallet: 'Carteira Atual',
      dashboard: 'Painel de Controle',
      data_added: 'Dados adicionados',
      data_saved: 'Dados Salvos',
      datas: 'Dados',
      date: 'Data',
      date_first_installment: 'Data do primeiro pagamento',
      ddi: 'Código do País',
      deb_de_trader: 'Débito de Trader',
      deb_game_donate: 'Débito Game Donate',
      deb_saldo: 'Débito Saldo',
      deb_saldo_disp: 'Débito Saldo Disponível',
      deb_saldo_pendent: 'Débito do Saldo Pendente',
      deb_val_indev: 'Débito Valor Indevido',
      debit_card_payment: 'Pagamento em cartão de débito',
      debit_card_payment_return: 'Devolução de pagamento em cartão de débito',
      debit_card_receipt: 'Recebimento em cartão de débito',
      debt: 'Débito',
      debt_td: 'Debito',
      December: 'Dezembro',
      decImpRenda: 'Declara imposto de Renda',
      deleted_document: 'Documento foi excluído do seu cadastro',
      department: 'Departamento',
      dependents: 'Dependentes',
      desc: 'Descendente',
      description: 'Descrição',
      details: 'Detalhes',
      dev_econom: 'Desenvolvimento Econônico',
      developer: 'Desenvolvedor',
      directly_indicated: 'Indicado diretamente',
      directly_indicateds: 'Indicados diretamente',
      directs: 'Diretos',
      district: 'Bairro/Distrito',
      divorced: 'Divorciado',
      doa_men_receb: 'Doação da Plataforma Mensal Recebida',
      doacao_td: 'Doação',
      doctorate_complete: 'Doutorado - Completo',
      doctorate_incomplete: 'Doutorado - Incompleto',
      document: 'Documento',
      document_already_registered: 'Documento já cadastrado',
      documents: 'Documentos',
      donate: 'Doar',
      donor: 'Doador',
      dont_know_zip: 'Não sabe o CEP? Clique aqui',
      dou_fe: 'Confirmo que as informações acima estão corretas.',
      downloads: 'Baixar Arquivos',
      drop_images: 'Clique ou Arraste as imagens até aqui',
      due_date: 'Vencimento',
      edit: 'Editar',
      edit_option: 'Editar opção',
      edit_question: 'Editar questão',
      email: 'Email',
      email_already_registered: 'E-mail já registrado',
      email_and_or_password_wrong: 'E-mail e/ou Senha errados!',
      email_for_login: 'Esse email será o seu login',
      email_or_password_wrong: 'Email ou senha incorretos',
      employee: 'Funcionário',
      employees: 'Funcionários',
      empty: 'Vazio',
      enter_your_new_password: 'Digite sua nova senha',
      entrada: 'Possui valor de entrada? Se SIM, qual valor',
      entries_until_today: 'Entradas até hoje',
      error_confirm_password: 'A Confirmação e a Nova Senha devem ser iguais',
      error_email: 'Formato de Email Inválido',
      error_password: 'Senha Incorreta',
      error_phone: 'Número de Telefone Inválido',
      estadoInteresse: 'Estado',
      estimate: 'Estimado',
      exchange: 'Troca',
      expired: 'Expirado',
      extorno_td: 'Estorno',
      extra: 'Extra',
      extract: 'Extrato',
      extracts: 'Extratos',
      fantasy: 'Fantasia',
      fatherName: 'Nome do Pai',
      February: 'Fevereiro',
      fee: 'Taxa de Saque',
      feedback: 'Retorno',
      feminine: 'Feminino',
      fgts: 'Valor do seu FGTS',
      field_required: 'Preenchimento obrigatório',
      final: 'Final',
      finance: 'Financeiro',
      financial: 'Financeiro',
      financial_payment: 'Pagamento de financeira',
      financial_payment_return: 'Devolução de pagamento de financeira',
      financial_receipt: 'Recebimento de financeira',
      financing: 'Financiamento',
      financings: 'Financiamentos',
      first_10_pages: 'Primeiras 10 páginas',
      fisica: 'Pessoa Física',
      for_year: 'Por Ano',
      forget_password: 'Esqueci a Senha',
      forgot_password: 'Esqueceu a senha',
      form: 'Forma',
      form_of_payment: 'Forma de Pagamento',
      found: 'Encontrado',
      founds: 'Encontrados',
      free: 'Livre',
      friend_indicated: 'Indicado pelos meus amigos',
      friend_indicateds: 'Indicados pelos meus amigos',
      fuel: 'Combustível',
      full_name: 'Nome Completo',
      funcPublico: 'É Funcionário Público',
      fundamental_complete: 'Fundamental - Completo',
      fundamental_incomplete: 'Fundamental - Incompleto',
      ganh_diario: 'Ganhos Diários',
      gender: 'Gênero',
      grand_total: 'Total Geral',
      group: 'Grupo',
      has_withdraw_today: 'Já foi solicitada uma retirada hoje',
      high: 'Alto',
      home: 'Início',
      how_create_binance_apikey: 'Como criar a Chave de API na Binance',
      how_it_works: 'Como Funciona',
      how_to_call: 'Como quer ser chamado',
      id_td: 'Indicação Direta',
      images: 'Imagens',
      in_construction: 'Em Construção',
      in_order: 'Em ordem',
      in_stock: 'Em estoque',
      indicant: 'Indicante',
      indicated_by: 'Você Está Sendo Indicado Por',
      indicated_not_found: 'O Indicante informado não foi encontrado...',
      indicateds: 'Indicados',
      indirects: 'Indiretos',
      info: 'Informativos',
      info_contact: 'Informações de contato',
      initial: 'Inicial',
      insert_new: 'Insira Novo',
      installments: 'Parcelas',
      intelligence: 'Inteligência',
      interesse: 'Qual é a região de interesse',
      invalid_contact: 'Formato de Contato inválido',
      invalid_images: 'Imagens inválidas',
      invalid_token: 'Token Inválido',
      invite: 'Convidar',
      invite_friends: 'Convide seus amigos',
      January: 'Janeiro',
      July: 'Julho',
      June: 'Junho',
      juridica: 'Pessoa Jurídica',
      know_exact_result: 'Sabe o valor Exato?',
      lead: 'Juntos estaremos construindo um novo futuro.',
      learn_more: 'Últimos sorteios',
      left: 'Esquerda',
      left_preferential: 'Preferindo o lado Esquerdo',
      legalNature: 'Natureza Jurídica',
      level: 'Nível',
      limite_80: 'Você atingiu {{perc}} do seu limite.\n Faça uma Melhoria e evite a suspensão dos seus rendimentos.',
      limite_excedido: 'Débito de limite excedido',
      linear: 'Linear',
      link_email: 'Clique no Link Enviado Para o Email Cadastrado!',
      link_invite_copy: 'Link de compartilhamento copiado com sucesso',
      liquid: 'Líquido',
      list: 'Lista',
      loading: 'Carregando',
      location: 'Localização',
      locked: 'Bloqueado',
      login: 'Entrar',
      logout: 'Sair',
      low: 'Baixo',
      male: 'Masculino',
      manage_users: 'Gerenciar Usuários',
      manager: 'Gerente',
      mandala: 'Mandala',
      mandalas: 'Mandalas',
      manufacturer: 'Fabricante',
      March: 'Março',
      maritalStatus: 'Estado Civil',
      market: 'Mercado',
      married: 'Casado',
      master_complete: 'Mestrado - Completo',
      master_incomplete: 'Mestrado - Incompleto',
      max_payment: 'Pagamento Máximo',
      May: 'Maio',
      message: 'Mensagem',
      messageNotSent: 'Não foi possível enviar sua mensagem',
      messageSent: 'Sua mensagem foi enviada com sucesso',
      middle_complete: 'Médio - Completo',
      middle_incomplete: 'Médio - Incompleto',
      min_amount_error: 'O valor deve ser no mínimo: ',
      min_withdraw: 'Valor Mínimo para Saque',
      missing: 'Faltam',
      mission: 'Missão',
      mission_vision_values: 'Missão, Visão e Valores',
      model: 'Modelo',
      monitors: 'Monitores',
      monthly: 'Mensalidade',
      motherName: 'Nome da Mãe',
      movements: 'Movimentos',
      msg_renovacao: 'Você atingiu seu limite de ganho, renove seu pacote.',
      my_account: 'Minha Conta',
      my_wallet: 'Minha Carteira',
      mystock: 'Meu Estoque',
      name: 'Nome',
      nascMaisVelhoRenda2: 'Qual a Data de nascimento do mais velho',
      nationality: 'Nacionalidade (País onde nasceu)',
      naturalness: 'Naturalidade (Cidade onde nasceu)',
      nature: 'Natureza',
      network: 'Rede',
      new: 'Novo',
      new_call: 'Novo Chamado',
      new_financing: 'Novo Financiamento',
      new_order: 'Nova Ordem',
      new_password: 'Nova Senha de Acesso',
      new_password_security: 'Nova senha de segurança',
      new_seller: 'Novo Vendedor',
      new_wallet: 'Nova Carteira',
      news: 'Notícias',
      next: 'Próximo',
      nick: 'Usuário (login)',
      nis: 'PIS/NIS',
      nis_already_registered: 'PIS/NIS já registrado',
      no: 'Não',
      no_approve: 'Ainda não aprovar',
      no_delete: 'Não excluir',
      no_literate: 'Não Alfabetizado',
      no_repeat: 'Os dados não podem ser repetidos',
      no_results_found: 'Nenhum resultado encontrado',
      no_services_or_products_added: 'Nenhum serviço ou produto adicionado',
      no_settings: 'Vá para Configurações e preencha seus dados.',
      none_friends_indicated: 'Ainda não indiquei nenhum amigo',
      not_authorized: 'Não autorizado',
      not_found: 'Não encontrado',
      not_login: 'Não foi possível entrar, verifique os dados e tente novamente!',
      not_orders: 'Sem Ordens de Pagamento',
      not_permission: 'Você não tem permissão para acessar essa página',
      not_registered: 'Ainda não está Cadastrado? Clique aqui e Cadastre-se',
      not_results: 'Nenhum Registro Encontrado',
      not_self_register: 'Não foi possível cadastrar',
      not_today_entry: 'Ainda não tivemos entradas hoje',
      notfound_ad: 'Nenhum anúncio encontrado',
      November: 'Novembro',
      number: 'Número',
      obs: 'Observações',
      obs_pay:
        'Se sua corretora debitar as taxas do valor enviado, você deverá fazer a correção manualmente acrescentando os valores dessas taxas.',
      October: 'Outubro',
      of: 'de',
      off: 'Desligado',
      on_total: 'Do Total de',
      open: 'Aberto',
      opening: 'Abertura',
      operation: 'Atuação',
      ordenation: 'Ordenação',
      order: 'Pedido',
      order_book: 'Livro de ordens',
      order_not_alowed_to_pay: 'Pedido não autorizado para pagar com saldo',
      order_not_found: 'Pedido não encontrado',
      order_payed: 'Pedido #{{id}} pago com sucesso',
      orders: 'Pedidos',
      other: 'Outro',
      other_services_or_products: 'Outros Serviços ou Produtos',
      paid: 'Pago',
      paid_date: 'Data do Pagamento',
      partial: 'Parcialmente Pago',
      passport: 'Passaporte',
      password: 'Senha',
      password_security: 'Senha de segurança',
      pay: 'Pagar',
      pay_exact: 'Pague o valor em {{coin}}',
      pay_generate: 'Gerar pagamento',
      pay_selected: 'Pagar Selecionados',
      pay_value_in: 'Pague o valor em',
      pay_with_balance: 'Pagar com Saldo',
      payable: 'Pagar',
      payer: 'Pagador',
      payment: 'Pagamento',
      ped_retirada: 'Pedido de Retirada',
      ped_retirada_gan_diario: 'Pedido de Retirada de Ganhos Diários',
      pendent: 'Pendente',
      pendent_order: 'Você tem Pedidos Pendentes',
      people: 'Pessoas',
      person: 'Pessoa',
      person_found_in_other_prefecture: 'Pessoa já cadastrada em outro município',
      personal: 'Pessoal',
      personal_addresses: 'Endereços',
      personal_contacts: 'Contatos',
      personal_data: 'Dados Pessoais',
      personal_infos: 'Informações',
      personal_pcva: 'Programa Casa Verde e Amarela',
      phone: 'Número do Telefone',
      photo: 'Foto',
      pix: 'PIX',
      pix_payment: 'Pagamento em PIX',
      pix_payment_return: 'Devolução de pagamento em PIX',
      pix_receipt: 'Recebimento em PIX',
      pl_carreira: 'Plano de Carreira',
      plan: 'Plano',
      plates: 'Placas',
      please_wait: 'Por favor, aguarde',
      plus: 'Plus',
      point: 'Ponto',
      points: 'Pontos',
      portfolio: 'Portfólio',
      pos_complete: 'Lato senso - Completo',
      pos_incomplete: 'Lato senso - Incompleto',
      prefecture: 'Prefeitura',
      prefecture_addresses: 'Endereços',
      prefecture_contacts: 'Contatos',
      prefecture_data: 'Dados da Prefeitura',
      prefecture_infos: 'Informações',
      prefectures: 'Prefeituras',
      prev: 'Anterior',
      price: 'Preço',
      print: 'Imprimir',
      privacy_policies: 'Políticas de Privacidade',
      private_works: 'Obras Privadas',
      profession: 'Profissão',
      profile: 'Perfil',
      program: 'Programa',
      programs: 'Programas',
      project: 'Projeto',
      projects: 'Projetos',
      public_works: 'Obras Públicas',
      purchase: 'Compra',
      qualify_binary: 'Qualificação Binário',
      qualquer: 'Qualquer',
      quantity: 'Quantidade',
      quotation: 'Cotação',
      read: 'Ler',
      receivable: 'Receber',
      recipient: 'Recebedor',
      recommendations: 'Recomendações',
      redefine_password: 'Redefinir Senha',
      register: 'Registro',
      register_people: 'Registro de Pessoas',
      register_wallet: 'Cadastre uma Carteira para realizar saques',
      registers: 'Registros',
      registers_users: 'Cadastros de Usuários',
      reject: 'Rejeitar',
      remaining: 'Restante',
      remove: 'Remover',
      rendaIndividual: 'Renda Bruta Individual',
      renew: 'Renovar',
      renew_mandala: 'Renovação da Mandala',
      reply: 'Responder',
      reports: 'Relatórios',
      required_data_not_sent: 'Dados necessários não enviados',
      resend_email: 'Reenviar o email',
      residential: 'Residencial',
      responded: 'Respondido',
      restricao: 'Possui restrição em seu nome? (SPC/Serasa)',
      result: 'Resultado',
      results: 'Resultados',
      resume_data: 'Resumo dos dados',
      revoked: 'Revogado',
      rg: 'Registro Geral (Identidade)',
      rgAgency: 'Emissor do RG',
      rgDate: 'Data de Emissão do RG',
      rgUf: 'Estado do RG',
      right: 'Direita',
      right_preferential: 'Preferindo o lado Direito',
      roi: 'Ganhos Diários',
      sale: 'Venda',
      saq_duplicado: 'Saque Duplicado',
      save: 'Salvar',
      schooling: 'Escolaridade',
      search: 'Buscar',
      secretary: 'Secretário',
      security_password: 'Senha de segurança',
      see: 'Ver',
      see_advents: 'Veja os anúncios',
      see_call: 'Ver chamado',
      see_details: 'Ver detalhes',
      see_details_wallet: 'Ver Detalhes da Carteira',
      see_financing: 'Ver financiamento',
      see_order: 'Ver pedido',
      see_user: 'Ver Usuários',
      select_vehicle: 'Selecione o veículo',
      self_register: 'Cadastre-se',
      sell_volume: 'Volume de Venda',
      seller: 'Vendedor',
      send_call: 'Enviar Chamado',
      send_to: 'Enviar para',
      September: 'Setembro',
      settings: 'Configurações',
      share_your_link: 'Compartilhe seu Link',
      show: 'Exibir até',
      show_from: 'Exibindo de',
      side: 'Lado',
      sign_in_to_start_your_session: 'Faça o login para iniciar sua sessão',
      sign_up: 'Cadastre-se',
      single: 'Solteiro',
      sold: 'Vendido',
      state: 'Estado',
      status: 'Status',
      strategies: 'Estratégias',
      street: 'Rua',
      subject: 'Assunto',
      submit: 'Enviar',
      submit_placeholder: 'Por favor, preencha seu email',
      subscribe: 'Inscreva-se para receber nossas novidades',
      subtitle: 'Subtítulo',
      success_update_user: 'Usuário atualizado com sucesso!',
      success_wallet: 'Carteira atualizada com Sucesso!',
      suggestions: 'Sugestões',
      superior_complete: 'Superior - Completo',
      superior_incomplete: 'Superior - Incompleto',
      supervisor: 'Supervisor',
      support: 'Suporte',
      support_materials: 'Materiais de Apoio',
      suspended: 'Suspenso',
      symbol: 'Símbolo',
      symbols: 'Símbolos',
      system: 'Sistema',
      target: 'Meta',
      telegram_chat_id: 'Telegram Chat ID',
      tempoCarteira: 'Quanto tempo de registro possui em carteira',
      terms: 'Aceito os termos de utilização',
      text_hint_binance:
        'Ao criar a chave de API na Binance insira nas restrições de acesso o IP:\n172.105.78.139\nEm seguida marque as opções:\n ENABLE EUROPEAN OPTIONS\n ENABLE SPOT & MARGIN TRADING',
      this_value_in: 'Esse valor em',
      ticker_health: 'Ticker Health',
      title: 'Título',
      to: 'Até',
      to_pay: 'A pagar',
      to_receive: 'A receber',
      today_entry: 'Entrada de Hoje',
      token_not_found_or_already_used: 'Token não encontrado ou já foi usado',
      total: 'Total',
      total_entry: 'Entrada Total',
      traderbot: 'Bot Trader',
      transf_credito: 'Crédito de Transferência',
      transf_debito: 'Débito de Transferência',
      transfer_payment: 'Pagamento em transferência',
      transfer_payment_return: 'Devolução de pagamento em transferência',
      transfer_receipt: 'Recebimento em transferência',
      transparency: 'Tranparência',
      tx_adm_cred: 'Taxa Administrativa',
      tx_adm_deb: 'Taxa Administrativa',
      tx_retirada: 'Taxa de Retirada',
      tx_retirada_gan_diario: 'Taxa de Retirada de Ganhos Diários',
      type: 'Tipo',
      update: 'Atualizar',
      update_wallet: 'Atualizar Carteira',
      upgrade: 'Melhoria',
      used: 'Usado',
      user: 'Usuário',
      user_active: 'Usuário ativo',
      user_already_exists: 'Usuário já existe!',
      user_and_or_password_wrong: 'Usuário e/ou Senha errados!',
      user_inactive: 'Usuário inativo',
      user_not_found: 'Usuário não encontrado',
      user_not_logged: 'Usuário não logado',
      user_or_password_wrong: 'Usuário ou senha incorretos',
      users: 'Usuários',
      users_list: 'Lista de Usuários',
      valid_images: 'Imagens válidas',
      valorRenda2: 'Qual o valor da segunda renda',
      value: 'Valor',
      value_donate_on: 'Valor da Doação em ',
      value_financed: 'Valor financiado',
      value_installment: 'Valor da parcela',
      values: 'Valores',
      vehicle: 'Veículo',
      vehicles: 'Veículos',
      view_order: 'Ver Ordem',
      vision: 'Visão',
      visitor: 'Visitante',
      voucher: 'Voucher',
      wait_confirm: 'Aguardando confirmação',
      waiting: 'Aguardando',
      waiting_email: 'O Cadastro foi Realizado com Sucesso, Agora Só Falta Confirmar o Email.',
      waiting_payment: 'Aguardando Pagamento',
      wallet: 'Carteira',
      wallet_generate: 'Gerar Carteira',
      wallets: 'Carteiras',
      want_donate: 'Quero Doar ',
      want_now_more_about: 'Quero saber mais sobre a ',
      warning: 'ATENÇÃO',
      we_are: 'Somos',
      webhooks: 'Webhooks',
      welcome: 'Seja Bem-Vindo',
      who_we_are: 'Quem Somos',
      who_we_are_subtitle: 'Uma Breve descrição sobre a',
      widower: 'Viúvo',
      will_expire_in: 'vai expirar em',
      with: 'com',
      withdraw: 'Retirada',
      withdraw_sended_email: 'Saque solicitado, verifique seu e-mail e clique no link para autorizar!',
      withdrawal: 'Saques',
      withdrawals: 'Retiradas',
      year: 'Ano',
      years: 'Anos',
      yes: 'Sim',
      you_have: 'Você tem',
      your_country: 'Seu País',
      your_dashboard: 'Este é o seu Painel de Controle.',
      your_link: 'Seu Link',
      zipcode: 'CEP',
      withdrawal_sended: 'Retirada enviada',
      withdrawal_pending: 'Retirada já solicitada! \n Por Favor, aguarde o pagamento para realizar outra retirada.',
      limite_80_top:
        'Você atingiu {{perc}} do seu limite.\n Antecipe sua renovação e evite a suspensão dos seus rendimentos.',
      game: 'Jogo',
      games: 'Jogos',
      winning_numbers: 'Números Sorteados',
      your_numbers: 'Seus Números',
      add_numbers: 'Adicionar Números',
      next_draw: 'O sorteio começará em',
      can_bet_until: 'Pode apostar até',
      your_bets: 'Suas Apostas',
      add_bet: 'Adicionar Aposta',
      bet: 'Aposta',
      bets: 'Apostas',
      numbers: 'Números',
      special_numbers: 'Números Especiais',
      resume: 'Resumo',
      choosed_numbers: 'Números Escolhidos',
      choosed_special_numbers: 'Números Especiais Escolhidos',
      bets_finished: 'Apostas Encerradas',
      prize_draw: 'Sorteio',
      balance_network: 'Saldo da Rede',
      telegram: 'Telegram',
      logs: 'Logs',
      release_withdrawals: 'Liberar Saques',
      bot_active: 'Liberar Bot',
      available: 'Disponível',
      sunday: 'Domingo',
      monday: 'Segunda',
      tuesday: 'Terça',
      wednesday: 'Quarta',
      thursday: 'Quinta',
      friday: 'Sexta',
      saturday: 'Sábado',
      title_binary: 'Defina as porcentagens do Binário para cada dia',
      ok_perc_change: 'Porcentagens alteradas com sucesso',
      cred_deb_title: 'Credite ou Debite saldo',
      send: 'Enviar',
      voucher_pendent: 'Voucher Pendente',
      leader_pendent: 'Líder Pendente',
      voucher_approved: 'Voucher Aprovado',
      leader_approved: 'Líder Aprovado',
      no_binary_at_today: 'Não há Binário registrado nesse dia',
      generate_file: 'Gerar arquivo',
      withdrawals_file_created: 'Arquivo de saques criado',
      withdrawals_sended: 'Solicitações de Saques Enviadas',
      see_generated_files: 'Ver arquivos gerados',
      files: 'Arquivos',
      file: 'Arquivo',
      verify: 'Verificar',
      rescue: 'Resgate',
      awarded: 'Premiado',
      rescued: 'Resgatado',
      repeat: 'Repetir',
      draw_date: 'Data do Sorteio',
      bet_date: 'Data da Aposta',
      drawn_numbers: 'Números Sorteados',
      awardeds_numbers: 'Números Premiados',
      no_award: 'Nenhum Prêmio',
      you_hit_number: 'Você acertou {{qty}} número',
      you_hit_numbers: 'Você acertou {{qty}} números',
      lottery: 'Loteria',
      lotteries: 'Loterias',
      balls: 'bolas',
      special_balls: 'Especiais',
      levy: 'arrecadação',
      major_awarded: 'mais bolas',
      hits: 'acertos',
      draw_due: 'Limite para apostar',
      close_game: 'Fechar Jogo',
      draw_due_greater_than_draw_date: 'Limite para apostar deve ser pelo menos {{timeLimit}} minutos antes do sorteio',
      draw_date_less_than_now: 'Data do Sorteio deve maior que a data atual em pelo menos {{timeLimit}} minutos',
      balance_game: 'Saldo',
      your_balance: 'Seu Saldo',
      your_balance_total: 'Seu Saldo Total',
      game_package: '{{qty}} numeros (pode ganhar {{prize}} vezes)',
      choose_package: 'Escolha o pacote',
      error_bet: 'não foi possível enviar a aposta',
      do_complete: 'Completar',
      success_bet: 'Aposta enviada',
      you_bet_number: 'Voce apostou {{qty}} números',
      winner_msg: 'e ganhou {{prize}} vezes',
      prize: 'Prêmio',
      balance_not_enough: 'Saldo insuficiente',
      addbalance: 'Adicionar Saldo',
      convertbalance: 'Converter Saldos',
      convertbalance_title: 'Converter Saldo Disponível em Saldo',
      can_award: 'Você pode ganhar',
      emission: 'Emissão',
      validation: 'Validação',
      participant: 'Participante',
      concourse: 'Concurso',
      draw_hours: 'Hora do Sorteio',
      qty_dezenas: 'Quantidade de Dezenas',
      bet_amount: 'Valor Apostado',
      share: 'Compartilhar',
      shared: 'Preparado para compartilhamento',
      simulation: 'Simulação',
      valuePay: 'Valor a Pagar',
      quotas_paid_success: 'Pagamento com sucesso',
      paids_today: 'Pagamentos Realizados Hoje',
      no_paids_today: 'Nenhum pagamento realizado hoje',
      perc: 'Porcentagem',
      define_percent: 'Definir Porcentagem',
      success_update: 'Alterado com sucesso',
      define_percent_next_payment: 'Defina a porcentagem para o proximo pagamento',
      better: 'Consultor Apostador',
      lottery_consultant: 'Consultor Associado',
      create: 'Criar',
      doclose: 'Fechar',
      qtyToAward: 'Qtd para premiar',
      oddLimit: 'Odd Limite',
      lottery_updated: 'Loteria Atualizada',
      lottery_created: 'Loteria Criada',
      award_packages: 'Pacotes de Prêmios',
      qty: 'Qtd',
      multiplier: 'Fator',
      maxValue: 'Max',
      limitRepeat: 'Limite',
      draw_day: 'Dia do Sorteio',
      day: 'Dia',
      hour: 'Hora',
      minutes: 'Minutos',
      ytb_channel_id: 'ID do Canal do Youtube',
      tutorial: 'Tutoriais',
      video_url: 'URL do Vídeo',
      tutorial_created: 'Tutorial criado',
      tutorial_deleted: 'Tutorial Excluído',
      tutorial_error_delete: 'Não foi possível excluir o tutorial',
      tutorial_updated: 'Tutorial Atualizado',
      tutorial_error_update: 'Não foi possível atualizar o tutorial',
      english: 'Inglês',
      spanish: 'Espanhol',
      portuguese: 'Português',
      select_language: 'Selecione o Idioma',
      language: 'Idioma',
      add_balance_games: 'Adicionar Saldo',
      consultant_indication: 'Indicação de Consultor',
      mensal_activation: 'Ativação Mensal',
      conversion: 'Conversão entre saldos',
      betting_commission: 'Comissão de Aposta',
      be_a_consultant: 'Seja um Consultor Investidor e ganhe muito mais',
      msg_be_a_consultant:
        'Divisão de Lucros Global da {{name}}.\nBônus de Indicação (sobre vendas de pacotes)\nBônus Binário \ne muito mais.',
      auto_renovations: 'Renovações Automáticas',
      auto_renove_binary: 'Auto-renovação Binário',
      auto_renove_quotas: 'Auto-renovação de Metas (220%)',
      auto_renove_anual: 'Auto-renovaçção Anual',
      on: 'Ligado',
      change_success: 'Alterado com sucesso',
      anual: 'Anual',
      only_monday: 'Retiradas estarão disponíveis todas as Segundas-feiras',
      transferency: 'Transferência',
      max_amount: 'Valor máximo',
      info_better:
        '- Recebe 100% do seu Pack de entrada e renovações em CRÉDITOS para apostas.\n\n- Pode fazer novas recargas de crédito de apostas sempre que desejar.\n\n- Participa de todo plano de negócios, podendo ter ganhos de indicações diretas e indiretas, Bônus Binário, Unilevel, Plano de Carreira e prêmios.',
      info_consultant:
        '- NÃO RECEBE os CRÉDITOS para apostas, O CONSULTOR FUNDADOR vende seus CRÉDITOS para apostas do seu Pack de entrada e renovações para a {{name}} negocia-los, e por isso recebe ATÉ 1% nos dias úteis sobre o valor de seu pack até atingir 220% podendo então renovar se desejar.\n\n- Pode fazer recargas de crédito de apostas sempre que desejar.\n\n- Participa de todo plano de negócios, podendo ter ganhos de indicações diretas e indiretas, Bônus Binário, Unilevel, Plano de Carreira e prêmios. ',
      confirm: 'Confirmar',
      send_new_token: 'Enviado a confirmação para seu e-mail, clique no seu email para confirmar',
      email_resended: 'Reenviado a confirmação para seu e-mail, clique no seu email para confirmar',
      expired_send_new_token: 'Enviado nova confirmação para seu e-mail, clique no seu email para confirmar',
      become_a_consultant: 'Faça um Upgrade e ganhe muito mais',
      register_ok: 'Cadastrado com sucesso',
      ticket_number: 'Número do Bilhete',
      conversion_to_bet: 'Conversão para Apostar',
      conversion_balance_games: 'Conversão de Saldo para Saldo de Jogos',
      conversion_balance_lotterprocoin: 'Conversão de Saldo para Ativações',
      conversion_lotterprocoin_games: 'Conversão de Ativações para Saldo de Jogos',
      conta_corrente: 'Conta Corrente',
      balance_games: 'Saldo de Jogos',
      transf_debit_lotterProCoin: 'Transferência de Saldo de Ativações',
      deb_saldo_lotterProCoin: 'Débito de Saldo de Ativações',
      transf_credit_lotterProCoin: 'Crédito de Transferência para Ativações',
      live_now: 'Ao vivo',
      offline: 'Aguardando Live',
      official_channel: 'Canal Oficial',
      tutorials: 'Tutoriais',
      msg_renove_binary:
        'Já completou mais um mês da última ativação mensal e você não está com a ativação mensal automatica ativada.\nPor isso seu Binário está inativo.\nPague um pedido de adicionar saldo de jogos no valor de $25.00 para realizar a ativação mensal.',
      deb_balance_games: 'Debito de Saldo de Jogos',
      cred_saldo_lotterProCoin: 'Crédito de Saldo de Ativações',
      live_hours: 'Horário da Live',
      choose_side_preferential: 'Escolha o lado preferencial (atualmente: {{side}})',
      count: 'Contagem',
      historics: 'Histórico',
      balance_lotterProCoin: 'Saldo de Ativações',
      msg_binary_tree_1: 'Pressione o usuário desejado por 1 segundo para ver as informações',
      msg_binary_tree_2: 'Clique no usuário desejado uma vez para ver os próximos no binário.',
      change_security_password: 'Alterar Senha de Segurança',
      info_pass_security: 'A senha de segurança é utilizada para realizar movimentações financeiras',
      redefine_security_password: 'Redefinir senha de segurança',
      redefined_password: 'Senha de acesso redefinida com sucesso!',
      click_to_enter: 'Clique para entrar',
      title_banner: 'Bem vindo a {{company}}, {{name}}!',
      descryption_banner:
        'Copie abaixo o seu link de cadastro e compartilhe com seus amigos para maximizar os seus ganhos',
      no_binaries: 'Não pode exibir a rede desse usuário',
      invalid_wallet: 'Carteira inválida',
      valid_wallet: 'Carteira válida',
      wallet_usdc: 'Carteira USDC',
      kyc: 'Verificação de identidade (KYC)',
      kyc_welcome: 'Para verificar sua identidade, por favor, envie as imagens abaixo:',
      kyc_msg:
        'Quando a verificação de identidade for aprovada, você receberá um e-mail de confirmação e estaram liberadas todas as funcionalidades da {{company}}.',
      kyc_error: 'Ocorreu um erro ao enviar as imagens, por favor, tente novamente',
      driving: 'CNH',
      identity: 'Identidade',
      front: 'Frente',
      front_msg: 'Foto da frente do Documento',
      back_doc: 'Verso',
      back_doc_msg: 'Foto do verso do Documento',
      selfie: 'Selfie',
      selfie_msg: 'Selfie segurando o documento',
      kyc_pending: 'Verificação pendente',
      kyc_rejected: 'Verificação rejeitada',
      kyc_approved: 'Verificação aprovada',
      comunicado_20240705:
        'Equipe da aibot orgulhosamente vem comunicar que após o início da comercialização das licenças nossa IA está pronto para realizar operações. \n Pedimos a todos que atualizem seus dados e enviem seus documentos para o início de uma grande jornada. \n\n Tudo inicia-se 24 horas após envio e aprovação dos documentos de cada conta. \n\n Aproveite para adquirir suas licenças pois são limitadas, e garanta o valor duplicado dentro de sua corretora Binance ou Coinbase',
      customer: 'Cliente',
      incomes: 'Rendimentos',
      title_incomes: 'Defina as porcentagens dos Rendimentos para cada dia',
      days: 'Dias',
			dayly: 'Diário',
			estorno_comission: 'Estorno de comissão',
			investiment: 'Investimento',
			earnings: 'Rendimentos',
    },
  },
}
export { messages }
